import { GoogleOAuthProvider } from '@react-oauth/google';
import { ConfirmationDialog, CustomTooltip, GoogleSignInButton, IBaseUser, IGoogleLoginError, IGoogleLoginSuccess, PillButton, ProjectDialog } from '@zz2/zz2-ui';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../@types/redux';
import { useGetUser, useHandleLinkGoogleAccount, useHandleUnlinkGoogleAccount } from '../../hooks/query/user/userQueries';
import GeneralThunks from '../../store/general/thunk';
import { IconButton, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

interface IBaseUserSettingsProps {
    isOpen : boolean;
    onClose : () => void;
    onLogout : () => void;
    currentUser : IBaseUser;
}

const UserSettings = (props : IBaseUserSettingsProps) : React.ReactElement => {
    const dispatch = useAppDispatch(); 
    const [unlinkEmailDialogOpen, setUnlinkEmailDialogOpen] = useState<boolean>(false);
    const [isLogOutDialogOpen, setIsLogOutDialogOpen] = useState<boolean>(false);

    const { isFetching: isFetchingUser, data: user, refetch: refetchUser } = useGetUser(props.currentUser.id, false);
    const { isLoading: isUnlinkingEmail, mutate: unlinkEmail, isSuccess: emailUnlinkSuccessful } = useHandleUnlinkGoogleAccount();
    const { isLoading: isLinkingGoogleAccount, mutate: linkGoogleAccount, isSuccess: emailLinkSuccessful } = useHandleLinkGoogleAccount();

    useEffect(() => {
        refetchUser();

    }, [props.isOpen, emailLinkSuccessful]);

    useEffect(() => {
        setUnlinkEmailDialogOpen(false);
        refetchUser();

    }, [emailUnlinkSuccessful]);

    const onGoogleLogInSuccess = async (response : IGoogleLoginSuccess) : Promise<void> => {
        linkGoogleAccount(response.code);
        refetchUser();
    };

    const onGoogleLogInFailure = (response : IGoogleLoginError) : void => {
        dispatch(GeneralThunks.showErrorSnackbar({
            defaultMessage: response.error ?? 'Linking Error',
            ex: response,
        }));
    };

    const onUnlinkEmailClick = () => setUnlinkEmailDialogOpen(true);
    const onLogoutClick = () => setIsLogOutDialogOpen(true);

    const handleEmailUnlinking = () => {
        unlinkEmail();
    };

    const handleLogout = () => {
        props.onClose();
        props.onLogout();
    };

    return (
        <>
            <ProjectDialog
                title={'User Settings'}
                isOpen={props.isOpen}
                maxWidth={'sm'}
                isLoadingCircular={isFetchingUser || isLinkingGoogleAccount}
                onClose={props.onClose}>
                <div className={'flx1 fdc aic jcc m10'}>
                    <div className={'fdc h100 flx1 aic jcc mb10'}>
                        <div className={'fdr aic'}>
                            {
                                user?.email == null 
                                    ? <div className={'zi1'}> 
                                        <GoogleOAuthProvider clientId={OAUTH_CLIENT_ID}>
                                            <GoogleSignInButton          
                                                onGoogleSignInSuccess={onGoogleLogInSuccess}
                                                onGoogleSignInFailure={onGoogleLogInFailure}   
                                                buttonText={'Link Google Account'}                                   
                                            />
                                        </GoogleOAuthProvider>
                                    </div>
                                    : <div className={'fdc aic jcc'}>
                                        <div className={'fdr aic jcc'}>
                                            <Typography className={'fdr m10'}>Linked Email:</Typography>
                                            <Typography variant={'body1'} className={'fdr fwb cpd m10'}>{ user.email }</Typography>
                                            <CustomTooltip title={'Unlink Email'}>
                                                <IconButton className={'cr'} onClick={onUnlinkEmailClick}>
                                                    <ClearIcon />
                                                </IconButton>
                                            </CustomTooltip>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                    <div className={'fdr aic pb20'}>
                        <PillButton
                            className={'h35 w150'}
                            text={'LOGOUT'}
                            color={'secondary'}
                            onClick={onLogoutClick}
                        />
                    </div>
                </div>
                <ConfirmationDialog 
                    open={unlinkEmailDialogOpen}
                    title={'Unlink Email'}
                    isLoading={isUnlinkingEmail}
                    description={'Are you sure you would like to unlink the email address currently associated with your account?'}
                    onAccept={handleEmailUnlinking}
                    onClose={() => setUnlinkEmailDialogOpen(false)}
                    dialogType={'orange'}
                />
                <ConfirmationDialog
                    isLoading={false}
                    open={isLogOutDialogOpen}
                    title={'Log Out?'}
                    description={'Are you sure you would like to logout?'}
                    onAccept={handleLogout}
                    onClose={() => setIsLogOutDialogOpen(false)}
                    dialogType={'red'}
                />
            </ProjectDialog>
        </>
    );
};

export default UserSettings;